<template>
  <div class="container with-sidebar">
    <Sidebar />
    <div>
      <div>
        <h1>Pflanzen</h1>
        <p>
          Beginnen wir mit einer kleinen Enttäuschung. Wir sind kein Gemüsebauernhof oder Gärtnerei.
        </p>
        <p>Wir befinden uns in einer Landschaft, die auf Spanisch Dehesa genannt wird. Es ist ein offener Wald, der seit
          Jahrhunderten für Viehzucht genutzt wird. Es gibt keine Felder, die mit einem Traktor
          bewirtschaftet werden oder was man sonst noch mit Ackerbau assoziieren würde.
        </p>
        <p>Wir konzentrieren uns jedoch sehr auf Pflanzen.</p>
        <p>Es gibt zwei Kategorien von Pflanzen, die für uns am wichtigsten sind. Die jahrhundertealten Eichen (meist
          Quercus ilex sp. bellota) und viele verschiedene Gräser. Die Eichen produzieren viele Eicheln, die ein
          wichtiges Futter für das <router-link :to="{ name: 'animalsPigs' }">Iberische Schwein</router-link> sind. Die
          Gräser ernähren unsere <router-link :to="{ name: 'animalsCattle' }">Rinder</router-link> und andere
          Wiederkäuer.
        </p>
        <p>Außerdem muss alles, was wir tun, im Kontext eines <router-link :to="{ name: 'concept' }">Projekts zur
            Wiederherstellung des Ökosystems</router-link> gesehen werden und nicht nur aus der Perspektive der
          landwirtschaftlichen Produktion . Schritt für Schritt arbeiten wir daran, das Ganze zu managen, anstatt uns
          auf wenige Teile zu konzentrieren, die möglicherweise nur von monetärem Interesse sind. Im Laufe der Zeit
          werden weitere Details darüber veröffentlicht.</p>

      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/views/concept/Sidebar.vue';

export default {
  name: "PlantsDE",
  components: {
    Sidebar,
  },

};
</script>
